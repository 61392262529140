import classes from "./Header.module.css";

function Header() {
  return (
    <header className={classes.header}>
      <h1>Enterijer Stanojlović</h1>
      <div>
        <a href="mailto:enterijer.stanojlovic@gmail.com" target="blank">
          <i className="fa-solid fa-square-envelope"></i>
        </a>
        <a
          href="https://www.instagram.com/enterijer_stanojlovic?igsh=cTA0bzc1bm5meGpn"
          target="blank"
        >
          <i className="fa-brands fa-square-instagram"></i>
        </a>
        <a href="tel:+381694011169" target="blank">
          <i className="fa-solid fa-phone"></i>
        </a>
      </div>
    </header>
  );
}

export default Header;
