import { useState } from "react";
import classes from "./Image.module.css";
import Dot from "./Dot";

function Image({ image, title }) {
  const [curr, setCurr] = useState(0);

  function handleLeft() {
    setCurr((prevCurr) => (prevCurr === 0 ? image.length - 1 : prevCurr - 1));
  }
  function handleRight() {
    setCurr((prevCurr) => (prevCurr === image.length - 1 ? 0 : prevCurr + 1));
  }
  return (
    <div className={classes.imageBackground}>
      <h3>{title}</h3>
      <div className={classes.image}>
        {image.length > 1 && <Dot image={image} curr={curr} />}
        <div
          className={classes.size}
          style={{ transform: `translateX(-${curr * 100}%)` }}
        >
          {image.map((item) => (
            <img src={item.img} alt={item.id} key={item.id} />
          ))}
        </div>
        {image.length > 1 && (
          <div className={classes.button}>
            <button onClick={handleLeft}>
              <i className="fa-solid fa-chevron-left left-arrow left-js"></i>
            </button>
            <button onClick={handleRight}>
              <i className="fa-solid fa-chevron-right right-arrow right-js"></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Image;
