import classes from "./Head.module.css";
import Header from "./Header";

function Head() {
  return (
    <div className={classes.head}>
      <Header />
      <div className={classes.title}>
        <h1>
          Enterijer Stanojlović,<br />
          <span className={classes.opacity}>
            mesto gde ideje postaju stvarnost.
          </span>
        </h1>
        <p>
          <span className={classes.ankar}>
            <a href="tel:+381694011169" target="blank">
              0694011169
            </a>
          </span>
          <span className={classes.ankar}>
            <a href="mailto:enterijer.stanojlovic@gmail.com" target="blank">
              enterijer.stanojlovic@gmail.com
            </a>
          </span>
        </p>
      </div>
    </div>
  );
}

export default Head;
