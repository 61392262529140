import Footer from "./components/Footer";
import Head from "./components/Head";
import Output from './components/Output-images'
import Main from "./components/Main";

function App() {
  return (
    <>
      <Head />
      <Main />
      <Output />
      <Footer />
    </>
  );
}

export default App;
