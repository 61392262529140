import classes from './Dot.module.css'

function Dot({ image, curr }) {
  return (
    <div className={classes['image-dot']}>
      {image.map((item, index) => {
        let clas = `${classes.dot}`

        if (curr === index) {
          clas += ` ${classes.active}`
        }
        return (
          <div key={item.id} className={clas}></div>
        );
      })}
    </div>
  );
}
export default Dot;