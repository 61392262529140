import classes from "./Main.module.css";

function Main() {
  return (
    <main className={classes.main}>
      <h2>
        Stvoreno po meri, oblikovano s ljubavlju. Dobro došli u Enterijer
        Stanojlović,
      </h2>
      <div className={classes.text}>
        <p>
          specijalizovanu firmu za proizvodnju nameštaja po meri i uredjenje
          kompletnog enterijera. Od ideje do realizacije, pretvaramo vaš prostor
          u mesto koje odiše funkcionalnošću. Naš tim stručnjaka se posvećuje
          svakom projektu, kako biste bezbrižno uživali u svom novom enterijeru.
        </p>
        <p>
          Sa više od decenije iskustva u industriji, Enterijer Stanojlović pruža
          jedinstvena rešenja za uredjenje enterijera. U Enterijeru Stanojlović
          verujemo da svaki detalj doprinosi savršenstvu. Pored izrade unikatnih
          komada, brinemo o svakom koraku – od prvobitne ideje do završne
          montaže u vašem prostoru, i sa ponosom pružamo vrhunski kvalitet
          izrade.
          <br />
        </p>
      </div>
      <h3>
        U Enterijeru Stanojlović nudimo širok spektar usluga za Vaš dom ili
        poslovni prostor:
      </h3>
    </main>
  );
}

export default Main;
