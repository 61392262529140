import Ending from "./Ending";
import Image from "./Image";
import classes from "./Images.module.css";

function Images({ first, second, third }) {
  return (
    <div className={classes.images}>
      <div className={classes.first}>
        <Image image={first.image} title={first.title} />
        <p className={classes.para}>{first.text}</p>
      </div>
      <div className={classes.second}>
        {second ? (
          <>
            <Image image={second.image} title={second.title} />
            <p className={classes.para}>{second.text}</p>
          </>
        ) : (
          <Ending />
        )}
      </div>
      <div className={classes.third}>
        <Image image={third.image} title={third.title} />
        <p className={classes.para}>{third.text}</p>
      </div>
      <div className={classes.fourth}>
        {second ? (
          <>
            <Image image={second.image} title={second.title} />
            <p className={classes.para}>{second.text}</p>
          </>
        ) : (
          <Ending />
        )}
      </div>
    </div>
  );
}

export default Images;
