import { images } from "../util/items"
import Images from "./Images"

function Output({children}) {
  return (
    <div>
      <Images first={images.spavaca} second={images.decija} third={images.dnevna}/>
      <Images first={images.americki} second={images.komode} third={images.standardni} />
      <Images first={images.kuhinja} second={images.kupatila} third={images.predsoblje}/>
      <Images first={images.poslovni} third={images.trpezarija} second={null}/>
    </div>
  )
}

export default Output