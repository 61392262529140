import classes from "./Ending.module.css";

function Ending() {
  return (
    <div className={classes.ending}>
      <p>
        <span>Enterijer Stanojlovic</span> - mesto gde ideje postaju stvarnost.
        <br /> Verujemo da svaki prostor nosi priču, a naša misija je da vam
        pomognemo da tu priču ispričate na jedinstven, autentičan način. Svaki
        komad nameštaja, svaki detalj, stvoren je sa ljubavlju, pažljivo
        prilagođen vašim željama i potrebama. Porodično smo posvećeni svakom
        projektu, jer verujemo da dom nije samo mesto, već osećaj. Dozvolite nam
        da budemo deo vašeg putovanja ka savršenom prostoru. Vaša ideja, naša
        inspiracija. Enterijer
        <br />
        Stanojlović - stvoreno po meri, oblikovano s ljubavlju.
      </p>
    </div>
  );
}

export default Ending;
